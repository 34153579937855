import React from "react";
//import { Route, redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// Sécurise les routes en empéchant les personne non connecté d'accéder à ces pages

const PrivateRoutes = () => {
  let auth = useSelector((state) => state.user.token);
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const AuthRoutes = () => {
  let auth = useSelector((state) => state.user.token);
  return auth ? <Navigate to="/" /> : <Outlet />;
};
// Empèche le user d'accèder au page de connexion et d'inscription si il est connecté en le redirigant vers la home

export { PrivateRoutes, AuthRoutes };
