import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "./Button";
import PageContainer from "./PageContainer";
import { FiLogOut } from "react-icons/fi";
import { logout } from "../features/userSlice";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const notyf = new Notyf({ dismissible: true, duration: 10000 });

const CardStep = styled.div`
  width: 500px;
  background-color: red;
  border-radius: 30px;
  background: #dedede;
  box-shadow: 41px 41px 82px #8a8a8a, -41px -41px 82px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
`;
const Welcome = styled.h2``;

export default function UserSettings() {
  const user = useSelector((state) => state.user.user);
  const date = new Date(user.dateCreation);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const lisibleDate =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  let price = 0;
  let credits = 0;
  if (user.subscription.product !== "none" && user.customPlan.active) {
    price = user.customPlan.price;
    credits = user.customPlan.videoNumber;
  }
  if (user.subscription.product === "starter") {
    price = 39;
    credits = 100;
  }
  if (user.subscription.product === "growth") {
    price = 79;
    credits = 200;
  }

  function handleCancelSub() {
    setLoading(true);
    axios
      .delete("/api/user/cancelsub")
      .then(({ data }) => {
        window.location.reload();
        notyf.success("Votre abonnement est résilier");
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        notyf.error(e.message);
      });
  }
  return (
    <PageContainer>
      <CardStep>
        <Welcome>Bonjour, {user.name}</Welcome>
        <p>
          <b>Plan</b> : {user.subscription.product}
        </p>
        <p>
          <b>Prix </b> :{" "}
          {user.subscription.payment === "year"
            ? Math.round((price * 10) / 12)
            : price}
          € / mois
        </p>
        <p>
          <b>Email</b> : {user.email}
        </p>
        <p>
          <b>Nombre de crédits par mois </b> : {user.credits}/{credits}
        </p>
        <p>
          <b>Date de création du compte</b> : {lisibleDate}
        </p>
        <Button
          onClick={() => {
            dispatch(logout());
            window.location.reload();
          }}
          color="#FF5959">
          <FiLogOut />
        </Button>
        {user.subscription.subId && user.subscription.subId !== "none" && (
          <Button onClick={handleCancelSub} disabled={loading} color="#FF5959">
            Résilier son abonnement
          </Button>
        )}
      </CardStep>
    </PageContainer>
  );
}
