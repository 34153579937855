import React, { useEffect, useState } from "react";
import PageContainer from "./PageContainer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ExportJsonCsv } from "react-export-json-csv";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import animationDataLoadingReq from "../asset/reqloader.json";
import Lottie from "react-lottie";
const notyf = new Notyf();

const TableLeads = styled(DataTable)`
  width: 80%;
  border-radius: 20px;
  background: #d6d6d6;
  box-shadow: 0px 0px 30px #b6b6b6, -20px -0px 30px #f6f6f6;
  transition: 0.15s;
`;
const CampainTitle = styled.p`
  margin: 0;

  margin-top: 13px;
  font-size: 30px;
  text-align: center;
  width: 80%;
  margin-bottom: 15px;
  text-align: start;
  display: flex;
  justify-content: space-between;
`;
const DownloadBtn = styled(ExportJsonCsv)`
  background-color: #24a19c;
  padding: 8px 20px;
  border: 1px solid #24a19c;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2px 5px;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 5px 30px #24a19c;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
export default function Leads() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [downloadLead, setDownloadLead] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/campain/get-leads/${id}`)
      .then(({ data }) => {
        setLeads(data);
        setLoading(false);
      })
      .catch(({ response: { data } }) => {
        notyf.error(data);
      });
  }, []);
  useEffect(() => {
    for (let i = 0; i < leads.length; i++) {
      const lead = leads[i];
      lead.videoUrl = `${process.env.REACT_APP_LANDING_URL}/${lead._id}`;
      setDownloadLead((dleads) => [...dleads, lead]);
    }
  }, [loading]);
  return (
    <PageContainer>
      {!loading ? (
        <>
          <CampainTitle>
            Vos leads de la campagnes {leads[0]?.campain?.name} :
            <DownloadBtn
              fileTitle={`${leads[0]?.campain?.name}-export`}
              headers={
                leads[0]?.campain?.template === 1
                  ? [
                      {
                        key: "firstname",
                        name: "firstname",
                      },
                      {
                        name: "lastname",
                        key: "lastname",
                      },
                      {
                        key: "website1",
                        name: "website1",
                      },
                      {
                        name: "videoUrl",
                        key: "videoUrl",
                      },
                    ]
                  : [
                      {
                        key: "firstname",
                        name: "firstname",
                      },
                      {
                        name: "lastname",
                        key: "lastname",
                      },
                      {
                        key: "website1",
                        name: "website1",
                      },
                      {
                        key: "website2",
                        name: "website2",
                      },
                      {
                        name: "videoUrl",
                        key: "videoUrl",
                      },
                    ]
              }
              items={downloadLead}>
              Export .CSV
            </DownloadBtn>
          </CampainTitle>
          <TableLeads
            columns={
              leads[0]?.campain?.template === 1
                ? [
                    {
                      name: "Prénom",
                      selector: (row) => row.firstname,
                    },
                    {
                      name: "Nom",
                      selector: (row) => row.lastname,
                    },
                    {
                      name: "website1",
                      selector: (row) => row.website1,
                    },
                    {
                      name: "Vidéo URL",
                      selector: (row) => (
                        <a
                          target="blank"
                          title="cliquer pour prévisualiser"
                          href={`${process.env.REACT_APP_LANDING_URL}/${row._id}`}>
                          {process.env.REACT_APP_LANDING_URL}/{row._id}
                        </a>
                      ),
                    },
                  ]
                : [
                    {
                      name: "Prénom",
                      selector: (row) => row.firstname,
                    },
                    {
                      name: "Nom",
                      selector: (row) => row.lastname,
                    },
                    {
                      name: "website1",
                      selector: (row) => row.website1,
                    },
                    {
                      name: "website2",
                      selector: (row) => row.website2,
                    },
                    {
                      name: "Vidéo URL",
                      selector: (row) => (
                        <a
                          target="blank"
                          title="cliquer pour prévisualiser"
                          href={`${process.env.REACT_APP_LANDING_URL}/${row._id}`}>
                          {process.env.REACT_APP_LANDING_URL}/{row._id}
                        </a>
                      ),
                    },
                  ]
            }
            data={leads}
          />
        </>
      ) : (
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationDataLoadingReq,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          width={400}
        />
      )}
    </PageContainer>
  );
}
