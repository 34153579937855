import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  padding-top: 70px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e7e7e7;
`;

export default PageContainer;
