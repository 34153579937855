import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";

const Header = styled.nav`
  width: 100%;
  height: 70px;
  background-color: rgba(73, 149, 255, 0.8);
  position: fixed;

  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const HeaderTitle = styled(Link)`
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: white;
`;
const LinkHeaderCtn = styled.div`
  display: flex;
  align-items: center;
`;
const LinkHeader = styled.a`
  color: white;
  font-size: 17px;
  margin: 0px 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default function Nav() {
  let navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  return (
    <Header>
      <HeaderTitle to="/">Persolive</HeaderTitle>
      <LinkHeaderCtn>
        {user ? (
          <>
            <Button onClick={() => navigate("/campain-editor")}>
              Créer une campagnes
            </Button>
            <LinkHeader
              onClick={() => {
                navigate("/campain");
              }}>
              Mes campagnes
            </LinkHeader>
            <LinkHeader>Crédit restant : {user?.credits}</LinkHeader>
            <FaUser
              color="white"
              size={25}
              onClick={() => {
                navigate("/settings");
              }}
            />
          </>
        ) : (
          <>
            <Button onClick={() => navigate("/login")}>Connexion</Button>
            <Button onClick={() => navigate("/register")}>Inscription</Button>
          </>
        )}
      </LinkHeaderCtn>
    </Header>
  );
}
