import React from "react";
import styled from "styled-components";
import Input from "./Input";
import axios from "axios";
import PageContainer from "./PageContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../features/userSlice";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const CardStep = styled.div`
  width: 500px;
  //height: 450px;
  background-color: red;
  border-radius: 30px;
  background: #dedede;
  box-shadow: 41px 41px 82px #8a8a8a, -41px -41px 82px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
`;

const StepTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: 15px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonInput = styled.input`
  background-color: #24a19c;
  padding: 8px 20px;
  border: 1px solid #24a19c;
  color: #fff;
  margin: 2px 5px;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 5px 30px #24a19c;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export default function Login() {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Votre email est invalide")
      .required("Email requis"),
    password: yup.string().required("Mot de passe requis"),
  });
  const notyf = new Notyf();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  async function onSubmit(data) {
    axios
      .post("/api/auth/login", {
        email: data.email,
        password: data.password,
      })
      .then(({ data }) => {
        dispatch(login(data));
        reset();
        notyf.success("Connecter avec succés");
        setTimeout(() => {
          window.location.reload();
        }, 2500);
        //console.log(data);
      })
      .catch((err) => {
        setError(err.response.data.field || err.response.data.context.key, {
          type: "manuel",
          message: err.response.data.message,
        });
      });
  }

  return (
    <PageContainer>
      <CardStep>
        <StepTitle>Connectez-vous</StepTitle>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Email"
            label={"Email"}
            error={errors?.email?.message}
            {...register("email")}
          />
          <Input
            label="Mot de passe"
            placeholder="Mot de passe"
            {...register("password")}
            type="password"
            error={errors?.password?.message}
          />
          <ButtonInput type="submit" disabled={!isValid} value={"Connexion"} />
        </Form>
      </CardStep>
    </PageContainer>
  );
}
