import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import styled from "styled-components";
import Button from "./Button";

const StepTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: 15px;
`;

const ImageCsv = styled.img`
  width: 100%;
`;

const Link = styled.a`
  color: black;
  font-size: 17px;
  margin: 0px 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default function PopupInfo() {
  return (
    <Popup
      trigger={
        <Link className="button">Comment formater son fichier de leads ?</Link>
      }
      modal>
      <StepTitle>Comment formater son fichier de leads ?</StepTitle>
      <ImageCsv src="../../assets/csv-example.png" />
      <p>
        Vous n'êtes pas obligé de mettre le{" "}
        <b style={{ textDecoration: "underline" }}>website2</b> si vous voulez
        juste le profile Linkedin par exemple.
        <br /> Tout les autres champs sont{" "}
        <b style={{ textDecoration: "underline" }}>obligatoire ⛔️</b>
      </p>
    </Popup>
  );
}
