import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import Button from "./Button";

const PriceCard = styled.div`
  width: 25%;
  padding: 20px;
  border-radius: 50px;
  background: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -20px 20px 60px #bebebe, 20px -20px 60px #ffffff;
`;
const PlanTitle = styled.p`
  font-size: 35px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
`;
const Price = styled.p`
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 30px;
`;
const Separator = styled.hr`
  width: 80%;
`;
const Feature = styled.p`
  width: max-content;
  margin: 10px;
  font-size: 17px;
`;
const FeatureCtn = styled.div`
  display: flex;
  flex-direction: column;
`;
const VideoNumber = styled.p`
  font-size: 20px;
  margin: 10px 0;
  -webkit-text-shadow: #000000 1px 0 10px;
  text-shadow: #000000 1px 0 20px;
  width: max-content;
`;

export default function PriceCardComponent({
  price,
  name,
  videoNumber,
  planBackName,
  annual,
}) {
  const [loading, setLoading] = useState(false);
  function handleCreateSub() {
    setLoading(true);
    try {
      axios
        .get(`/api/user/checkout/${planBackName}?annual=${annual}`)
        .then(({ data }) => {
          window.location.replace(data.url);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } catch (error) {}
  }
  return (
    <PriceCard>
      <PlanTitle>{name}</PlanTitle>
      <Price> {annual ? Math.round((price * 10) / 12) : price}€ / mois</Price>
      <Separator />
      <VideoNumber>{videoNumber} vidéos / mois</VideoNumber>
      <FeatureCtn>
        <Feature>✅ CSV import/export</Feature>
        <Feature>✅ Landing page personnalisé</Feature>
        <Feature>✅ vidéos hébergées</Feature>
      </FeatureCtn>
      <Button disabled={loading} onClick={handleCreateSub}>
        Commencer
      </Button>
    </PriceCard>
  );
}
