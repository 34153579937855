import React from "react";
import PageContainer from "./PageContainer";
import animationData from "../asset/failed-animation.json";
import Lottie from "react-lottie";
import styled from "styled-components";

const Title = styled.h1`
  margin: 0;
`;

export default function FailedPayment() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <PageContainer>
      <Title>Ton inscrption n'a pas eu lieu</Title>
      <h3>Erreur au moment du paiment :/</h3>
      <Lottie options={defaultOptions} width={300} height={250} />
    </PageContainer>
  );
}
