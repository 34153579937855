import React, { useState } from "react";
import PageContainer from "./PageContainer";
import Lottie from "react-lottie";
import animationData from "../asset/home-animation.json";
import "../App.css";
import styled from "styled-components";
import MultiSwitch from "react-multi-switch-toggle";

import PriceCardComponent from "./PriceCard";
import { useSelector } from "react-redux";

const PriceCtn = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
`;
const AnnuelPromo = styled.p`
  margin: 0;
  margin-top: 10px;
  font-size: 23px;
`;

const ToggleBilled = styled(MultiSwitch)`
  user-select: none;
`;
const Title = styled.h1``;
export default function Home() {
  const { user } = useSelector((state) => state.user);
  const [annual, setAnnual] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <PageContainer>
      {!user.subscription.subId || user.subscription.subId === "none" ? (
        <>
          <ToggleBilled
            onToggleCallback={(i) => {
              i === 0 ? setAnnual(true) : setAnnual(false);
            }}
            texts={["Annuel", "Mensuel"]}
            eachSwitchWidth={150}
            height={"50px"}
            fontSize={"20px"}
            selectedFontColor={"#ffff"}
          />
          {annual && <AnnuelPromo>2 mois offerts 🤑</AnnuelPromo>}
          <PriceCtn>
            <PriceCardComponent
              name="Starter"
              price={39}
              planBackName="starter"
              videoNumber={100}
              annual={annual}
            />
            <PriceCardComponent
              name="Growth"
              price={79}
              planBackName="growth"
              videoNumber={"200"}
              annual={annual}
            />
            {user.customPlan.active && (
              <PriceCardComponent
                name={user.customPlan.name}
                planBackName={user.customPlan.name}
                price={user.customPlan.price}
                videoNumber={user.customPlan.videoNumber}
                annual={annual}
              />
            )}
          </PriceCtn>
        </>
      ) : (
        <>
          <Title>Bienvenue sur Persolive</Title>
          <Lottie options={defaultOptions} width={300} height={250} />
        </>
      )}
    </PageContainer>
  );
}
