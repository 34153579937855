import styled from "styled-components";

const Button = styled.button`
  background-color: ${(props) => (props.color ? props.color : "#24a19c")};
  padding: 8px 20px;
  border: 1px solid ${(props) => (props.color ? props.color : "#24a19c")};
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2px 5px;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 5px 30px
      ${(props) => (props.color ? props.color : "#24a19c")};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;
