import React, { useEffect, useState } from "react";
import PageContainer from "./PageContainer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addCampain, delCampain } from "../features/campainSlice";
import styled from "styled-components";
import withImageLoader from "react-image-loader-hoc";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { AiFillDelete, AiOutlineArrowRight } from "react-icons/ai";
import animationDataSucces from "../asset/succes-animation.json";
import animationDataFailed from "../asset/failed-animation.json";
import animationDataLoading from "../asset/home-animation.json";
import animationDataLoadingReq from "../asset/reqloader.json";
import animationEmpty from "../asset/empty-animation.json";
import Button from "./Button";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const notyf = new Notyf({ dismissible: true, duration: 10000 });

const Image = (props) => <img alt="" {...props} />;
const ImageWithLoader = withImageLoader(Image);

const CampainsCtn = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const ListCtn = styled.div`
  // display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
`;
const CampainTitle = styled.p`
  margin: 0;
  margin-bottom: 13px;
  font-size: 30px;
  text-align: center;
  width: 100%;
`;
const CampainCard = styled.div`
  border-radius: 50px;
  display: flex;
  margin: 15px 0px;
  padding: 20px;
  border-radius: 50px;
  background: #d6d6d6;
  box-shadow: 20px 20px 15px #b6b6b6, -20px -20px 60px #f6f6f6;
  transition: 0.15s;

  &:hover {
    box-shadow: 20px 20px 60px #b6b6b6, -20px -20px 60px #f6f6f6;
    transform: scale(1.003);
  }
`;
const Minia = styled(ImageWithLoader)`
  width: 150px;
  aspect-ratio: 1/1;
  border-radius: 30px;
  object-fit: cover;
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    transform: scale(0.96);
  }
`;
const PartLeft = styled.div`
  width: 50%;
  padding-left: 20px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;
const PartRight = styled.div`
  width: 50%;
  padding-left: 20px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
`;
const CampainInfo = styled.div`
  margin: 5px 0 0 0px;
`;
const BtnCtn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.h1``;

export default function Campain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campains = useSelector((state) => state.campains);
  const [loading, setLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/campain/get-campain")
      .then(({ data }) => {
        dispatch(addCampain(data));
        setLoading(false);
      })
      .catch(({ response: { data } }) => {
        console.error(data);
        notyf.error(data);
        setLoading(false);
      });
  }, []);
  function deleteCampain(id) {
    setDelLoading(true);
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette campagne ?")) {
      axios
        .delete(`/api/campain/remove-campain/${id}`)
        .then(({ data }) => {
          dispatch(delCampain(id));
          notyf.success(data);
          setDelLoading(false);
        })
        .catch(({ response: { data } }) => {
          notyf.error(data);
          setDelLoading(false);
        });
    } else {
      setDelLoading(false);
    }
  }
  return (
    <PageContainer>
      <CampainsCtn>
        <Title>Vos campagnes : </Title>
        {!loading ? (
          <ListCtn>
            {campains.length === 0 && (
              <>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationEmpty,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={300}
                  height={300}
                />
                <p>Vous n'avez pas de campagne 😕</p>
              </>
            )}
            {campains.map(
              ({
                name,
                dateCreation,
                _id,
                videoUrl,
                nbr_leads,
                calendlyEmbedUrl,
                campainState: { currentVideo, error, state },
              }) => {
                const date = new Date(dateCreation);

                const lisibleDate =
                  date.getDate() +
                  "/" +
                  (date.getMonth() + 1) +
                  "/" +
                  date.getFullYear();
                return (
                  <CampainCard key={_id}>
                    <Minia
                      onClick={() => {
                        window.open(
                          videoUrl.replace(".m3u8", ".mp4"),
                          "_blank"
                        );
                      }}
                      src={videoUrl.replace(".m3u8", ".jpg")}></Minia>
                    <PartLeft>
                      <CampainTitle>{name}</CampainTitle>
                      <CampainInfo>{lisibleDate}</CampainInfo>
                      <CampainInfo>Nombre de leads : {nbr_leads}</CampainInfo>
                      {calendlyEmbedUrl && (
                        <CampainInfo>
                          Url calendly :
                          <a
                            target="blank"
                            title="Cliquer pour prévisualiser"
                            href={calendlyEmbedUrl}>
                            {calendlyEmbedUrl.slice(0, 35) + "..."}
                          </a>
                        </CampainInfo>
                      )}
                    </PartLeft>
                    <PartRight>
                      <div>
                        <CampainInfo>
                          Génération de la campagnes : {currentVideo + 1}/
                          {nbr_leads}
                        </CampainInfo>
                        {(state === "progress" || state === "start") && (
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: animationDataLoading,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            width={150}
                            height={100}
                          />
                        )}
                        {state === "finish" && (
                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: animationDataSucces,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            width={100}
                            height={100}
                          />
                        )}
                        {state === "error" && (
                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: animationDataFailed,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            width={100}
                            height={100}
                          />
                        )}
                        <BtnCtn>
                          <Button
                            color="#FF4B4B"
                            disabled={delLoading}
                            onClick={() => deleteCampain(_id)}>
                            <AiFillDelete />
                          </Button>
                          <Button
                            color="#00DD4D"
                            onClick={() => navigate(`/campain/${_id}`)}>
                            <AiOutlineArrowRight />
                          </Button>
                        </BtnCtn>
                      </div>
                    </PartRight>
                  </CampainCard>
                );
              }
            )}
          </ListCtn>
        ) : (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationDataLoadingReq,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width={400}
          />
        )}
      </CampainsCtn>
    </PageContainer>
  );
}
