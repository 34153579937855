import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import campainReducer from "../features/campainSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    campains: campainReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
