import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "./PageContainer";
import { MultiStepForm, Step } from "react-multi-form";
import Input from "./Input";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Button from "./Button";
import Papa from "papaparse";
import VideoRecorder from "react-video-recorder";
import Switch from "react-switch";
import PopupInfo from "./PopupCSVInfo";
import axios from "axios";
import "../App.css";
import { Notyf } from "notyf";
import { useNavigate } from "react-router-dom";
import "notyf/notyf.min.css";
const notyf = new Notyf({ duration: 10000, dismissible: true });

const CardStep = styled.div`
  width: 500px;
  //height: 450px;
  background-color: red;
  border-radius: 30px;
  background: #dedede;
  box-shadow: 41px 41px 82px #8a8a8a, -41px -41px 82px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
`;

const StepTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: 15px;
`;

const CamCtn = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
`;

const UploadSettingButton = styled.label`
  display: flex;
  align-items: center;
`;

const Error = styled.p`
  font-size: 14px;
  padding: 10px;
  background-color: rgb(255, 88, 88);
  border-radius: 5px;
  color: white;
  margin-bottom: 30px;
  max-width: 70%;
  text-align: center;
`;

const SliderVideo = styled.input`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  margin-bottom: 10px;
  width: 400px; /* Full-width */
  height: 5px; /* Specified height */
  background-color: rgba(73, 149, 255, 0.8); /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 20px;
    background: #04aa6d;
    cursor: pointer;
    transition: 0.15s;
  }
  &::-webkit-slider-thumb:active,
  &::-webkit-slider-thumb:focus {
    height: 30px !important;
    background-color: red;
  }
`;

export default function CampainEditor() {
  const [active, setActive] = useState(1);
  const [error, setError] = useState("");
  const [campain, setCampain] = useState("");
  const [calendly, setCalendly] = useState("");
  const [leads, setLeads] = useState();
  const [transitionTime, setTransitionTime] = useState();
  const [video, setVideo] = useState();
  const [blob, setBlob] = useState();
  const [upload, setUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  window.onbeforeunload = function () {
    return "Data will be lost if you leave the page, are you sure?";
  };
  const calendlyRegex = new RegExp("https://calendly.com");
  const httpRegex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{2,7}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
  useEffect(() => {
    if (
      leads?.filter(function (el) {
        return el.website2;
      }).length >= 1
    ) {
      if (active === 4) {
        if (!transitionTime) {
          setError("Placez la transition entre vos 2 website");
        } else {
          setError("");
        }
      }
    }
    if (active === 1) {
      if (!campain) {
        setError("Veuillez remplire le Nom de la campagne *");
      } else {
        setError("");
      }
      if (calendly) {
        if (!calendlyRegex.test(calendly)) {
          console.log(calendly);
          setError(
            "L'url calendly n'a pas le bon format ('https://calendly.com/....')"
          );
        } else {
          setError("");
        }
      }
    } else if (active === 2) {
      if (!leads || leads?.length === 0) {
        setError("Uploader un fichier avec des leads");
      } else {
        for (let i = 0; i < leads.length; i++) {
          const lead = leads[i];
          if (!lead.firstname) {
            setError("Le lead " + (i + 1) + " n'a pas de fistname");
            break;
          } else {
            setError("");
          }
          if (!lead.lastname) {
            setError("Le lead " + (i + 1) + " n'a pas de lastname");
            break;
          } else {
            setError("");
          }
          if (!lead.website1) {
            setError("Le lead " + (i + 1) + " n'a pas de website1");
            break;
          }
          if (!httpRegex.test(lead.website1)) {
            setError(
              "Le lead " +
                (i + 2) +
                " à son website1 non valide (https://example.com/)"
            );
          }
          // Si il y a une dexième url
          if (lead.website2) {
            const verifArray = leads.filter(function (el) {
              return el.website2;
            });
            if (leads.length !== verifArray.length) {
              setError(
                "Vous devez obligatoirement renseigner un website2 si vous l'avez fait pour le reste des leads"
              );
              break;
            }
            if (!httpRegex.test(lead.website2)) {
              setError(
                "Le lead " +
                  (i + 2) +
                  " à son website2 non valide (https://example.com/)"
              );
              break;
            }
          } else {
            setError("");
          }
        }
      }
    }
  }, [
    video,
    campain,
    calendly,
    leads,
    video,
    upload,
    active,
    httpRegex,
    calendlyRegex,
    transitionTime,
  ]);

  function PreviouStep() {
    return (
      <Button color="#FFA84B" onClick={() => setActive((active) => active - 1)}>
        <AiOutlineArrowLeft />
        étape précédente
      </Button>
    );
  }
  function handleSubmit() {
    setLoading(true);
    const formData = new FormData();
    formData.append("leads", JSON.stringify(leads));
    formData.append("videoBrut", blob, blob.name);
    formData.append("campain_name", campain);
    formData.append("calendlyEmbedUrl", calendly);
    formData.append("transiTime", transitionTime);
    formData.append(
      "template",
      leads?.filter(function (el) {
        return el.website2;
      }).length >= 1
        ? 2
        : 1
    );

    axios
      .post("/api/campain/campain-generation", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        notyf.success(data);
        navigate("/");
        setLoading(false);
      })
      .catch(({ response: { data } }) => {
        notyf.error(data);
        setLoading(false);
      });
  }
  return (
    <PageContainer>
      {leads?.filter(function (el) {
        return el.website2;
      }).length >= 1 ? (
        <MultiStepForm activeStep={active}>
          <Step label={"Informations"}>
            <CardStep>
              <StepTitle>
                Veuillez saisir les Informations de la campagnes
              </StepTitle>
              {error && <Error>{error}</Error>}
              <Input
                label="Nom de la campagne *"
                value={campain}
                placeholder="CEO paris"
                onChange={(e) =>
                  setCampain(e.target.value.replace(/[^a-zA-Z0-9àéè ]/g, ""))
                }
              />
              <Input
                label="Url de l'embed calendly"
                value={calendly}
                placeholder="https://calendly.com/pobs-agence-digitale/sales?hide_event_type_details=1&hide_gdpr_banner=1"
                onChange={(e) => setCalendly(e.target.value)}
              />
              {!error && (
                <Button onClick={() => setActive(active + 1)}>Continuer</Button>
              )}
            </CardStep>
          </Step>
          <Step label={"Fichier CSV"}>
            <CardStep>
              <StepTitle>
                Veuillez uploader votre Fichier CSV de leads
              </StepTitle>
              <PopupInfo />

              {error && <Error>{error}</Error>}
              <Input
                type="file"
                accept=".csv"
                onChange={(e) =>
                  Papa.parse(e.target.files[0], {
                    delimiter: ",",
                    skipEmptyLines: true,
                    header: true,
                    complete: (result) => {
                      setLeads(result.data);
                    },
                  })
                }
              />
              {!error ? (
                <Button onClick={() => setActive((active) => active + 1)}>
                  Continuer avec {String(leads?.length)}
                  {leads?.length < 1 ? " lead" : " leads"}
                </Button>
              ) : (
                ""
              )}
              <PreviouStep />
            </CardStep>
          </Step>
          <Step label={"Video"}>
            <CardStep>
              <StepTitle>
                Uploadez ou enregistez une video d'une 1 minute maximum
              </StepTitle>
              {error && <Error>{error}</Error>}
              <UploadSettingButton>
                <span>uploadez votre propres video</span>
                <Switch onChange={setUpload} checked={upload} />
              </UploadSettingButton>
              <CamCtn>
                {!upload ? (
                  <VideoRecorder
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                    style={{ background: "no-repeat" }}
                    timeLimit={90000}
                    onRecordingComplete={async (videoBlob) => {
                      const file = await new File([videoBlob], "demo.webm", {
                        type: "video/webm",
                      });
                      if (file.size > 20000000) {
                        setVideo(null);
                        setError("Votre ne doir pas dépasser 20mo");
                      } else {
                        setBlob(file);
                        setVideo(URL.createObjectURL(file));
                        setError("");
                      }
                    }}
                  />
                ) : (
                  <VideoRecorder
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                    useVideoInput
                    timeLimit={90000}
                    onRecordingComplete={(videoBlob) => {
                      setBlob(videoBlob);
                      const file = new File([videoBlob], "demo.mp4");
                      setTimeout(() => {
                        if (file.size > 20000000) {
                          setVideo(null);
                          setError("Votre ne doir pas dépasser 20mo");
                        } else {
                          if (
                            document.querySelector(
                              ".video-recorder__Video-sc-7k20rv-2"
                            ).duration <= 91
                          ) {
                            setVideo(URL.createObjectURL(file));
                            setError("");
                            //setBlob(file);
                          } else {
                            setVideo(null);
                            setError("Votre ne doir pas dépasser 1 minutes");
                          }
                        }
                      }, 500);
                    }}
                  />
                )}
              </CamCtn>
              {video ? (
                <Button onClick={() => setActive((active) => active + 1)}>
                  Confirmer
                </Button>
              ) : (
                ""
              )}
              <PreviouStep />
            </CardStep>
          </Step>
          <Step label={"édition"}>
            <CardStep>
              <StepTitle>
                Mettez le curseur au moment ou vous voulez passer du website1 au
                website2
              </StepTitle>
              {error && <Error>{error}</Error>}

              <video
                className="video_preview"
                controls
                width={400}
                onTimeUpdate={(e) =>
                  setTransitionTime(Math.round(e.target.currentTime))
                }
                src={video}
              />
              <p>T'as transition est la seconde {transitionTime}</p>
              <SliderVideo
                type="range"
                min={1}
                onChange={(e) => {
                  setTransitionTime(e.target.value);
                  document.querySelector(".video_preview").currentTime =
                    e.target.value;
                }}
                value={transitionTime}
                max={
                  document.querySelector(".video_preview") &&
                  document.querySelector(".video_preview").duration
                }
              />
              {!error && (
                <Button onClick={() => setActive((active) => active + 1)}>
                  Confirmer
                </Button>
              )}
              <PreviouStep />
            </CardStep>
          </Step>
          <Step label={"Confirmer"}>
            <CardStep>
              <StepTitle>Confirmer les Informations de la campagne</StepTitle>
              <div style={{ margin: "0px 15px" }}>
                <p>
                  <b>Nom de la campagne * : </b>
                  {campain}
                </p>
                <p>
                  {calendly && (
                    <>
                      <b>Url calendly : </b>
                      {calendly}
                    </>
                  )}
                </p>
                <p>
                  <b>Nombre de {leads?.length < 1 ? " lead " : " leads "} : </b>
                  {String(leads?.length)}
                </p>
                <p>
                  <b>Transition à la seconde : </b>
                  {transitionTime}
                </p>
                <p>
                  <b>Vidéo de prospection : </b>
                  <br />
                  <video controls width={300} src={video} />
                </p>
              </div>
              {!error && (
                <>
                  <Button disabled={loading} onClick={() => handleSubmit()}>
                    Génerer les vidéos
                  </Button>
                </>
              )}
              <PreviouStep />
            </CardStep>
          </Step>
        </MultiStepForm>
      ) : (
        <MultiStepForm activeStep={active}>
          <Step label={"Informations"}>
            <CardStep>
              <StepTitle>
                Veuillez saisir les Informations de la campagnes
              </StepTitle>
              {error && <Error>{error}</Error>}
              <Input
                label="Nom de la campagne *"
                value={campain}
                placeholder="CEO paris"
                onChange={(e) =>
                  setCampain(e.target.value.replace(/[^a-zA-Z0-9àéè ]/g, ""))
                }
              />
              <Input
                label="Url de l'embed calendly"
                value={calendly}
                placeholder="https://calendly.com/pobs-agence-digitale/sales?hide_event_type_details=1&hide_gdpr_banner=1"
                onChange={(e) => setCalendly(e.target.value)}
              />
              {!error && (
                <Button onClick={() => setActive(active + 1)}>Continuer</Button>
              )}
            </CardStep>
          </Step>
          <Step label={"Fichier CSV"}>
            <CardStep>
              <StepTitle>
                Veuillez uploader votre Fichier CSV de leads
              </StepTitle>
              <PopupInfo />

              {error && <Error>{error}</Error>}
              <Input
                type="file"
                accept=".csv"
                onChange={(e) =>
                  Papa.parse(e.target.files[0], {
                    delimiter: ",",
                    skipEmptyLines: true,
                    header: true,
                    complete: (result) => {
                      setLeads(result.data);
                    },
                  })
                }
              />
              {!error ? (
                <Button onClick={() => setActive((active) => active + 1)}>
                  Continuer avec {String(leads?.length)}
                  {leads?.length < 1 ? " lead" : " leads"}
                </Button>
              ) : (
                ""
              )}
              <PreviouStep />
            </CardStep>
          </Step>
          <Step label={"Video"}>
            <CardStep>
              <StepTitle>
                Uploadez ou enregistez une video d'une 1 minute maximum
              </StepTitle>
              {error && <Error>{error}</Error>}
              <UploadSettingButton>
                <span>uploadez votre propres video</span>
                <Switch onChange={setUpload} checked={upload} />
              </UploadSettingButton>
              <CamCtn>
                {!upload ? (
                  <VideoRecorder
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                    timeLimit={90000}
                    onRecordingComplete={async (videoBlob) => {
                      const file = await new File([videoBlob], "demo.webm", {
                        type: "video/webm",
                      });
                      if (file.size > 20000000) {
                        setVideo(null);
                        setError(
                          "Votre ne doir pas dépasser 20mo, baissez la qualité de votre caméra"
                        );
                      } else {
                        setBlob(file);
                        setVideo(URL.createObjectURL(file));
                        setError("");
                      }
                    }}
                  />
                ) : (
                  <VideoRecorder
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                    useVideoInput
                    timeLimit={90000}
                    onRecordingComplete={(videoBlob) => {
                      setBlob(videoBlob);
                      const file = new File([videoBlob], "demo.mp4");
                      setTimeout(() => {
                        if (file.size > 20000000) {
                          setVideo(null);
                          setError("Votre ne doir pas dépasser 20mo");
                        } else {
                          if (
                            document.querySelector(
                              ".video-recorder__Video-sc-7k20rv-2"
                            ).duration <= 91
                          ) {
                            setVideo(URL.createObjectURL(file));
                            setError("");
                          } else {
                            setVideo(null);
                            setError("Votre ne doir pas dépasser 1 minutes");
                          }
                        }
                        // if (
                        //   document.querySelector(
                        //     ".video-recorder__Video-sc-7k20rv-2"
                        //   ).duration <= 60
                        // ) {
                        //   setVideo(URL.createObjectURL(file));
                        //   setError("");
                        // } else {
                        //   setVideo();
                        //   setError("Votre ne doir pas dépasser 1 minutes");
                        // }
                        // if (file.size > 20000000) {
                        //   setVideo(null);
                        //   setError("Votre ne doir pas dépasser 20mo");
                        // } else {
                        //   setBlob(file);
                        //   setVideo(URL.createObjectURL(file));
                        //   setError("");
                        // }
                      }, 100);
                    }}
                  />
                )}
              </CamCtn>
              {video ? (
                <Button onClick={() => setActive((active) => active + 1)}>
                  Confirmer
                </Button>
              ) : (
                ""
              )}
              <PreviouStep />
            </CardStep>
          </Step>
          <Step label={"Confirmer"}>
            <CardStep>
              <StepTitle>Confirmer les Informations de la campagne</StepTitle>
              {error && <Error>{error}</Error>}
              <div style={{ margin: "0px 15px" }}>
                <p>
                  <b>Nom de la campagne * : </b>
                  {campain}
                </p>
                <p>
                  {calendly && (
                    <>
                      <b>Url calendly : </b>
                      {calendly}
                    </>
                  )}
                </p>
                <p>
                  <b>Nombre de {leads?.length < 1 ? " lead " : " leads "} : </b>
                  {String(leads?.length)}
                </p>
                <p>
                  <b>Vidéo de prospection : </b>
                  <br />
                  <video controls width={300} src={video} />
                </p>
              </div>
              {!error && (
                <>
                  <Button disabled={loading} onClick={() => handleSubmit()}>
                    Génerer les vidéos
                  </Button>
                  <PreviouStep />
                </>
              )}
            </CardStep>
          </Step>
        </MultiStepForm>
      )}
    </PageContainer>
  );
}
