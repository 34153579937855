import React, { useState } from "react";
import styled from "styled-components";
import { BiShowAlt, BiHide } from "react-icons/bi";

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.95rem;
  outline: none;
  margin-top: 0.2rem;
  box-sizing: border-box;
  padding-right: ${(props) => props.type === "password" && "40px"};
  border: ${(props) => (props.error ? "1.5px" : "0px")} solid red;

  &:focus {
    border: 1.5px solid ${(props) => (props.error ? "red" : "#24a19c")};
  }
`;

const StyledLabel = styled.label`
  font-size: 0.9rem;
`;

const InputCtn = styled.div`
  text-align: start;
  width: 70%;
  margin-bottom: 1rem;
  user-select: none;
`;
const Error = styled.p`
  margin: 0;
  font-size: 0.75em;
  color: red;
`;

const InputPwdCtn = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const StyledIconShow = styled(BiShowAlt)`
  font-size: 25px;
  position: absolute;
  top: 25%;
  right: 10px;
`;

const StyledIconHide = styled(BiHide)`
  font-size: 25px;
  position: absolute;
  top: 25%;
  right: 10px;
`;

const Input = React.forwardRef((props, ref) => {
  const [showPwd, setShowPwd] = useState(false);

  function toggleHide() {
    console.log(showPwd);
    setShowPwd((d) => !d);
  }
  // return props.type === "password" ? (
  //   <InputCtn>
  //     <StyledLabel>{props.label}</StyledLabel>
  //     <InputPwdCtn>
  //       <StyledInput
  //         ref={ref}
  //         {...props}
  //         type={showPwd ? "text" : "password"}
  //       />
  //       {showPwd ? (
  //         <StyledIconHide onClick={toggleHide} />
  //       ) : (
  //         <StyledIconShow onClick={toggleHide} />
  //       )}
  //     </InputPwdCtn>
  //     {props.error && <Error>{props.error}</Error>}
  //   </InputCtn>
  // ) : (
  return (
    <InputCtn>
      <StyledLabel>{props.label}</StyledLabel>
      {props.type === "password" ? (
        <InputPwdCtn>
          <StyledInput
            ref={ref}
            {...props}
            type={showPwd ? "text" : "password"}
          />
          {showPwd ? (
            <StyledIconHide onClick={toggleHide} />
          ) : (
            <StyledIconShow onClick={toggleHide} />
          )}
        </InputPwdCtn>
      ) : (
        <StyledInput ref={ref} {...props} />
      )}
      {props.error && <Error>{props.error}</Error>}
    </InputCtn>
  );
  //);
});

export default Input;
