import React from "react";
import PageContainer from "./PageContainer";
import animationData from "../asset/succes-animation.json";
import Lottie from "react-lottie";
import styled from "styled-components";

const Title = styled.h1`
  margin: 0;
`;

export default function SuccesPayment() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <PageContainer>
      <Title>Ton inscrption est valider </Title>
      <h3>Tes crédits sont disponible 🔥</h3>
      <Lottie options={defaultOptions} width={300} height={250} />
    </PageContainer>
  );
}
