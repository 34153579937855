import { createSlice } from "@reduxjs/toolkit";

export const campainSlice = createSlice({
  name: "campains",
  initialState: [],
  reducers: {
    addCampain: (state, { payload }) => {
      return [...payload];
    },
    delCampain: (state, { payload }) => {
      return (state = state.filter(({ _id }) => _id !== payload));
    },
  },
});

export const { addCampain, delCampain } = campainSlice.actions;

export default campainSlice.reducer;
