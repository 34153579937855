import { Routes, Route } from "react-router-dom";
import Nav from "./Component/Nav";
import Home from "./Component/Home";
import CampainEditor from "./Component/CampainEditor";
import Login from "./Component/Login";
import axios from "axios";
import { PrivateRoutes, AuthRoutes } from "./Component/ProtectedRoute";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, login } from "./features/userSlice";
import UserSettings from "./Component/UserSettings";
import SuccesPayment from "./Component/SuccesPayment";
import FailedPayment from "./Component/FaildedPayment";
import Campain from "./Component/Campain";
import Leads from "./Component/Leads";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import Register from "./Component/Register";
const notyf = new Notyf({ dismissible: true, duration: 10000 });

function App() {
  const { user } = useSelector((state) => state.user);
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  if (user) {
    axios.defaults.headers.common["auth-token"] = localStorage.getItem("token");
  }
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        dispatch(logout());
        alert(
          "Pour des raisons de sécuriter veuillez vous reconnecter, votre dernière connexion au site remonte à plus de 2 jours."
        );
      } else {
        return Promise.reject(error);
      }
    }
  );
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "2918b7d7-cdda-4604-b2d1-0ff00ccc04b3";

  (function () {
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      axios
        .post("/api/auth/verifyToken", {
          token: localStorage.getItem("token"),
        })
        .then(({ data }) => {
          dispatch(login(data));
        })
        .catch(({ response: { data } }) => {
          notyf.error(data);
          console.log(data);
        });
    }
  }, []);
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/campain-editor" element={<CampainEditor />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/settings" element={<UserSettings />} />
          <Route path="/success" element={<SuccesPayment />} />
          <Route path="/failed" element={<FailedPayment />} />
          <Route path="/campain" element={<Campain />} />
          <Route path="/campain/:id" element={<Leads />} />
        </Route>
        <Route element={<AuthRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
